$fqm: (
    rgb(0, 175, 237),
    rgb(1, 125, 197),
    rgb(3, 77, 162),
    rgb(224, 225, 25),
    rgb(165, 204, 58),
    rgb(114, 190, 68),
);
$primary: rgb(114, 190, 68);
$hover-background: white;

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "../node_modules/bootstrap/scss/bootstrap";

@media screen {

    h5.card-header,
    .ag-header-cell,
    .modal-header,
    .ag-header-cell .ag-icon {
        background-color: rgb(3, 77, 162) !important;
        color: white !important;
    }

    thead th,
    h6.card-header {
        background-color: rgb(1, 125, 197) !important;
        color: white !important;
    }

    .btn-primary,
    .btn-primary:hover,
    .btn-primary:active {
        color: white !important;
    }

    body {
        background-color: lightgray;
    }
}

.print {
    display: none;
}

.form-control {
    padding: 0.3em;
}

span.form-control {
    border: 0;
}

.card,
.card-header:first-child {
    border-radius: 0;
}

.ag-cell-value,
.ag-cell {
    word-break: break-word;
    line-height: 1.5;
}

.ag-cell-wrapper {
    min-height: 100%;
}

.button-container {
    display: flex;
    background-color: lightgrey;
    position: sticky;
    z-index: 2;
    top: 0;
    padding-top: 1em;

    >div {
        margin-left: auto;

        >button {
            margin-left: 1em;
        }
    }

    button {
        margin-bottom: 1em;
    }
}

.card {
    margin-bottom: 1em;
}

.main-container {
    width: 100%;
    padding: 0em 1em 1em 1em;
}

.form-check-input {
    margin-right: 0.5em;
}

input[readonly] {
    background-color: transparent;
    border-color: rgba(0,0,0,0);
    font-size: 1em;
}

.form-group {
    display: inline-block;
    padding-right: 1em;
    vertical-align: top;
}

.card-header .fa {
    transition: .3s transform ease-in-out;
    margin-top: 0.15em;
}

.card-header .collapsed .fa {
    transform: rotate(1800deg);
}

h5.card-header {
    cursor: pointer;
}

.grid-buttons {
    line-height: normal;
}

label:not(.form-check-label):not(.form-label-base)::after {
    content: " :"
}

td {
    vertical-align: middle;
}

.col-form-label {
    vertical-align: top;
}

.total {
    border-top: 1px solid black;
}

.total,
.total input {
    font-weight: bold;
}

.textarea-print {
    display: none;
}

input[type="number"] {
    text-align: right;
}

.mnt::after {
    content: " $"
}
.mnt-th {
    width: 10em;
}
.mnt, .mnt-th, .num {
    .form-control {
        display: inline-block;
        width: 7em;
    }
    label {
        display: block;
        text-align: left;
    }
}

.card-body-forced-open textarea {
    padding: 0;
    border-color: transparent;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 1;
}

.form-select:disabled {
    background-color: transparent;
    border-color: transparent;
    background-image: none;
    padding-left: 0.3em;
}

.form-check-label {
    display: inline;
}